<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">排程记录</div>
        <div class="contentTopRight">
          <el-tooltip v-premiSub="'排程记录_删除'" class="item" effect="dark" content="删除" placement="top">
            <img :src=delStatusImg alt="" @click="deleteFn" style="margin-right:0px">
          </el-tooltip>
          <el-input placeholder="排程流水号" suffix-icon="el-icon-search" size="small" v-model="input2"
            @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable2" :data="Inuse" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="serialNumber" label="排程流水号">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column prop="createUser" label="创建人">
        </el-table-column>
        <el-table-column prop="scheduledStatus" label="状态">
          <template slot-scope="">
            <span>使用中</span>
          </template>
        </el-table-column>
        <el-table-column prop="serialNumber" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <img src="@/assets/images/plan/examine.png" alt="" @click="editFn(scope.row)">
            </el-tooltip>
            <el-tooltip v-if="scope.row.hasLogs" class="item" effect="dark" content="日志" placement="top">
              <img src="@/assets/images/plan/log.png" alt="" @click="logFn(scope.row)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content">
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleTabs">
          <el-tab-pane label="未发布" name="Unpublished">
            <el-table ref="multipleTable1" :data="Unpublished2" tooltip-effect="dark" style="width: 100%"
              @sort-change="sortChange" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="65">
              </el-table-column>
              <el-table-column prop="showId" label="序号" fixed="left" width="100">
              </el-table-column>
              <el-table-column prop="serialNumber" label="排程流水号" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createUser" label="创建人" sortable='custom'>
              </el-table-column>
              <el-table-column prop="scheduledStatus" label="状态">
                <template slot-scope="scope">
                  <span
                    :class="{ 'cell_Blue': (scope.row.scheduledStatus === 3), 'cell_Yellow': (scope.row.scheduledStatus === 1), 'cell_Green': (scope.row.scheduledStatus === 2) }"></span>
                  <span style="margin-left: 10px">未发布</span>
                </template>
              </el-table-column>

              <el-table-column v-if="apsRoleNumber == 1 || apsRoleNumber == 2" prop="plan" label="" sortable='custom'>
                <template slot="header" slot-scope="{}">
                  <el-checkbox v-premiSub="'勾选计划分析'" @change="checkedChange1" @click.stop.native="() => { }"
                    v-model="checkAll1">计划分析</el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-premiSub="'勾选计划分析'" v-model="scope.row.planAnalysisState"
                    @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column v-else prop="plan" label="">
                <template slot="header" slot-scope="{}">
                  <el-checkbox v-premiSub="'勾选计划分析'" @change="checkedChange1" @click.stop.native="() => { }"
                    v-model="checkAll1">计划分析</el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-premiSub="'勾选计划分析'" v-model="scope.row.planAnalysisState"
                    @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="serialNumber" label="操作">
                <template slot-scope="scope">
                  <el-tooltip v-premiSub="'排程记录_编辑'" v-if="scope.row.scheduledStatus === 1" class="item" effect="dark"
                    content="编辑" placement="top">
                    <img src="@/assets/images/plan/analysis/edit.png" alt="" @click="editFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-else class="item" effect="dark" content="查看详情" placement="top">
                    <img src="@/assets/images/plan/examine.png" alt="" @click="editFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-premiSub="'排程记录_删除'" v-if="scope.row.scheduledStatus === 1" class="item" effect="dark"
                    content="删除" placement="top">
                    <img src="@/assets/images/plan/analysis/delete.png" alt="" @click="delFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.hasLogs" class="item" effect="dark" content="日志" placement="top">
                    <img src="@/assets/images/plan/log.png" alt="" @click="logFn(scope.row)">
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="totalFlex">
              <span>共{{ total_1 }}条</span>
              <span>
                <el-pagination @size-change="handleSizeChange_1" background @current-change="handleCurrentChange_1"
                  :current-page="currentPage1_1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange_1
                  layout="prev, pager, next,sizes, jumper" :total=total_1>
                </el-pagination>
              </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="已发布" name="Published">
            <el-table ref="multipleTable3" :data="Published2" tooltip-effect="dark" style="width: 100%"
              @sort-change="sortChange2">
              <el-table-column prop="showId" label="序号" fixed="left" width="100">
              </el-table-column>
              <el-table-column prop="serialNumber" label="排程流水号" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createUser" label="创建人" sortable='custom'>
              </el-table-column>
              <el-table-column prop="scheduledStatus" label="状态">
                <template slot-scope="scope">
                  <span
                    :class="{ 'cell_Blue': (scope.row.scheduledStatus === 3), 'cell_Yellow': (scope.row.scheduledStatus === 1), 'cell_Green': (scope.row.scheduledStatus === 2) }"></span>
                  <span style="margin-left: 10px">已发布</span>
                </template>
              </el-table-column>
              <el-table-column v-if="apsRoleNumber == 1 || apsRoleNumber == 2" prop="" label="" sortable='custom'>
                <template slot="header" slot-scope="{}">
                  <el-checkbox v-premiSub="'勾选计划分析'" @change="checkedChange2" @click.stop.native="() => { }"
                    v-model="checkAll2">计划分析</el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-premiSub="'勾选计划分析'" v-model="scope.row.planAnalysisState"
                    @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column v-else prop="" label="">
                <template slot="header" slot-scope="{}">
                  <el-checkbox v-premiSub="'勾选计划分析'" @change="checkedChange2" @click.stop.native="() => { }"
                    v-model="checkAll2">计划分析</el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-premiSub="'勾选计划分析'" v-model="scope.row.planAnalysisState"
                    @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="serialNumber" label="操作">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
                    <img src="@/assets/images/plan/examine.png" alt="" @click="editFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.scheduledStatus === 1" class="item" effect="dark" content="删除"
                    placement="top">
                    <img src="@/assets/images/plan/analysis/delete.png" alt="" @click="delFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.hasLogs" class="item" effect="dark" content="日志" placement="top">
                    <img src="@/assets/images/plan/log.png" alt="" @click="logFn(scope.row)">
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="totalFlex">
              <span>共{{ total_2 }}条</span>
              <span>
                <el-pagination @size-change="handleSizeChange_2" background @current-change="handleCurrentChange_2"
                  :current-page="currentPage1_2" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange_2
                  layout="prev, pager, next,sizes, jumper" :total=total_2>
                </el-pagination>
              </span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ConfirmDel :dialogVisible="dialogVisible" @close="close" @ok="ok" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
const ConfirmDel = () => import("@/components/ConfirmDel.vue")
import { getSchedulingData, delScheduling, searchSchedulingData, errorLog, getSerialNumberSortValue, getCreateTimeSortValue, getCreateUserSortValue, getPlanAnalysisStateSortValue, updateSelected, allSelected } from '@/api/plan.js'
export default {
  name: "scheduling",
  components: {
    ConfirmDel
  },
  computed: {
    ...mapState(['apsRoleNumber']),
    delStatusImg() {
      return this.bulkDeletionStatus ? require("@/assets/images/plan/analysis/delete.png") : require("@/assets/images/plan/analysis/bulkDeletion.png")
    }
  },
  data() {
    return {
      activeName: 'Unpublished',
      Inuse: [],  // 使用中
      Unpublished2: [], // 未发布
      total_1: 0,
      currentPage1_1: 1,
      pageChange_1: 10,
      Published2: [], // 已发布
      total_2: 0,
      currentPage1_2: 1,
      pageChange_2: 10,
      bulkDeletionStatus: false,  // 多选删除的状态
      dialogVisible: false,
      tableData: [],
      multipleSelection: [],
      input2: '',
      section: [
        '已确认',
        '未确认'
      ],
      searchData: [],
      checkAll1: false,
      checkAll2: false,
      status: 1,  // 标签页切换，1为未发布，3为已发布
      direction: 0,
      direction2: 0,
      sort: '',
      sort2: '',
      searchStatus: false,
      allData1: [],  // 全部排程未发布的数据
      allData2: [],  // 全部排程已发布的数据
      temp1: [],
      temp2: [],
    }
  },
  watch: {
    checkAll1() {
      if (this.temp1.length !== this.allData1.length) {
        this.checkAll1 = false
      } else {
        this.checkAll1 = true
      }
    },
    checkAll2() {
      if (this.temp2.length !== this.allData2.length) {
        this.checkAll2 = false
      } else {
        this.checkAll2 = true
      }
    }
  },
  methods: {
    async checkedChange1(val) {
      const res = await allSelected(this.status, val ? 1 : 0)
      if (res.code == 0) {
        this.getData(this.currentPage1_1, this.pageChange_1, 1)
        if (val) {
          this.temp1 = this.allData1.map(v => v.id)
        } else {
          this.temp1 = []
        }
        val ? this.$set(this, 'checkAll1', true) : this.$set(this, 'checkAll1', false)
      }
    },
    async checkedChange2(val) {
      const res = await allSelected(this.status, val ? 1 : 0)
      if (res.code == 0) {
        this.getData(this.currentPage1_2, this.pageChange_2, 3)
        if (val) {
          this.temp2 = this.allData2.map(v => v.id)
        } else {
          this.temp2 = []
        }
        val ? this.$set(this, 'checkAll2', true) : this.$set(this, 'checkAll2', false)
      }
    },
    async sortChange(column) {
      this.sort = column.prop
      let num = 0
      if (column.order === "ascending") {
        num = 1
      } else if (column.order === "descending") {
        num = 2
      } else {
        num = 2
        this.sort = ''
      }
      this.direction = num
      this.getData(this.currentPage1_1, this.pageChange_1, 1)
    },
    async sortChange2(column) {
      this.sort2 = column.prop
      let num = 0
      if (column.order === "ascending") {
        num = 1
      } else if (column.order === "descending") {
        num = 2
      } else {
        num = 2
        this.sort2 = ''
      }
      this.direction2 = num
      this.getData(this.currentPage1_2, this.pageChange_2, 3)
    },
    async handleSizeChange_1(val) {
      // 页面分页为6、12、24、48条
      this.pageChange_1 = val
      this.currentPage1_1 = 1
      await this.getData(1, this.pageChange_1, 1)
    },
    async handleSizeChange_2(val) {
      this.pageChange_2 = val
      this.currentPage1_2 = 1
      await this.getData(1, this.pageChange_2, 3)
    },
    async handleCurrentChange_1(val) {
      // 点击页数1、2、3……
      this.currentPage1_1 = val
      await this.getData(this.currentPage1_1, this.pageChange_1, 1)
    },
    async handleCurrentChange_2(val) {
      this.currentPage1_2 = val
      await this.getData(this.currentPage1_2, this.pageChange_2, 3)
    },
    handleTabs(val) {
      if (val.paneName === 'Published') this.status = 3
      if (val.paneName === 'Unpublished') this.status = 1
    },
    // 跳转甘特图
    editFn(mark) {
      const { serialNumber, scheduledStatus, createUser } = mark
      // 根据流水号的首字符跳转不同的甘特图页面
      let pathVal = null
      if (serialNumber.charAt(0) === 'a') {
        pathVal = '/ganttAssembly'
      } else if (serialNumber.charAt(0) === 's') {
        pathVal = '/ganttProduction'
      } else {
        return
      }
      this.$router.push({
        path: pathVal,
        query: { serialNumber, scheduledStatus, createUser }
      })
    },
    // 多选删除
    async deleteFn() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning({
          showClose: true,
          message: '请选择需要删除的排程记录！',
          duration: 0
        })
        return
      }
      this.dialogVisible = true
    },
    // 单个删除
    delFn(mark) {
      this.delId = mark.id
      if (this.multipleSelection.length > 1) {
        this.$message.warning({
          showClose: true,
          message: '请点击批量删除按钮',
          duration: 0
        })
        return
      }
      this.dialogVisible = true
    },
    // 下载错误日志
    async logFn(val) {
      if (val.hasLogs) {
        const res = await errorLog(val.serialNumber)
        this.filename = '排程记录错误日志.xlsx'
        this.url = window.URL.createObjectURL(res)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.url
        link.setAttribute('download', this.filename)
        document.documentElement.appendChild(link)
        link.click()
        document.documentElement.removeChild(link)
      }
    },
    close() {
      this.dialogVisible = false
    },
    async ok() {
      this.dialogVisible = false
      let id = this.delId
      let delIdArr = []
      this.multipleSelection.forEach(v => delIdArr.push(v.id))
      if (delIdArr.length === 0) delIdArr.push(id)
      const res = await delScheduling(delIdArr)
      if (res.code === 0) {
        this.$message.success('删除成功！')
      } else {
        this.$message.error({
          showClose: true,
          message: res.msg,
          duration: 0
        })
      }
      this.input2 = ''
    },
    // 多选框
    handleSelectionChange(val) {
      this.multipleSelection = val
      val.length === 0 ? this.bulkDeletionStatus = false : this.bulkDeletionStatus = true
    },
    async searchHandler() {
      if (this.input2.length > 0) {
        const res = await searchSchedulingData(this.input2)
        // 搜索后的总条数
        this.searchData = res.data.records
        this.filterFn(this.searchData)
        this.searchStatus = true
      } else {
        this.searchStatus = false
        this.getAllData()
        this.currentPage1_1 = 1
        this.currentPage1_2 = 1
        this.pageChange_1 = 10
        this.pageChange_2 = 10
      }
    },
    async getData(page, size, filter, all = 0) {
      let res = null
      if (filter === 1 && this.sort) {
        // 未发布的排序
        switch (this.sort) {
          case 'serialNumber':
            res = await getSerialNumberSortValue(page, size, 1, this.direction)
            break
          case 'createTime':
            res = await getCreateTimeSortValue(page, size, 1, this.direction)
            break
          case 'createUser':
            res = await getCreateUserSortValue(page, size, 1, this.direction)
            break
          case 'plan':
            res = await getPlanAnalysisStateSortValue(page, size, 1, this.direction)
            break
          default:
            break
        }
      } else if (filter === 3 && this.sort2) {
        // 已发布的排序
        switch (this.sort2) {
          case 'serialNumber':
            res = await getSerialNumberSortValue(page, size, 3, this.direction2)
            break
          case 'createTime':
            res = await getCreateTimeSortValue(page, size, 3, this.direction2)
            break
          case 'createUser':
            res = await getCreateUserSortValue(page, size, 3, this.direction2)
            break
          case 'plan':
            res = await getPlanAnalysisStateSortValue(page, size, 3, this.direction2)
            break
          default:
            break
        }
      } else {
        res = await getSchedulingData(page, size, filter)
      }
      // 如果是在搜索情况下
      if (this.searchStatus) {
        return
      }
      const { records, total } = res.data
      if (all === 1) {
        // 全部数据
        this.allData1 = records
        // 筛选出allData1里面选中的，如planAnalysisState不为1，则不加入temp1
        this.temp1 = this.allData1.filter(v => v.planAnalysisState === 1).map(v => v.id)
        return
      } else if (all === 3) {
        this.allData2 = records
        this.temp2 = this.allData2.filter(v => v.planAnalysisState === 1).map(v => v.id)
        return
      }
      if (filter === 1) {
        // 未发布
        this.Unpublished2 = records
        this.total_1 = total  // 总条数，但搜索下会跟着变
        sessionStorage.setItem('total_1', total)
        this.Unpublished2.forEach(v => {
          if (v.planAnalysisState === 1) {
            this.$set(v, 'planAnalysisState', true)
          } else {
            this.$set(v, 'planAnalysisState', false)
          }
        })
        if (this.Unpublished2[0].isAllSelected == 1) {
          // 全选状态时，所有数据的isAllSelected都为1，这里只判断一条即可
          this.$set(this, 'checkAll1', true)
        } else {
          this.$set(this, 'checkAll1', false)
        }
      } else if (filter === 2) {
        // 使用中，显示生产计划、装配计划各一条，后端已过滤
        this.Inuse = records
      } else if (filter === 3) {
        this.Published2 = records
        this.total_2 = total
        sessionStorage.setItem('total_2', total)
        this.Published2.forEach(v => {
          if (v.planAnalysisState === 1) {
            this.$set(v, 'planAnalysisState', true)
          } else {
            this.$set(v, 'planAnalysisState', false)
          }
        })
        if (this.Published2[0].isAllSelected == 1) {
          // 同上
          this.$set(this, 'checkAll2', true)
        } else {
          this.$set(this, 'checkAll2', false)
        }
      }
    },
    filterFn(list) {
      // 搜索之后进行筛选，分别放到不同区域（未发布、已发布、使用中）
      let InuseFilter = []
      let status1 = false
      let status2 = false
      this.list = list
      list.forEach(v => {
        if (v.scheduledStatus == 2) InuseFilter.push(v)
        if (v.scheduledStatus == 1) status1 = true
        if (v.scheduledStatus == 3) status2 = true
      })
      if (InuseFilter.length !== 0) this.Inuse.push(InuseFilter[0])
      if (status1) {
        this.Unpublished2 = list.filter(v => v.scheduledStatus == 1)
        if (!status2) this.Published2 = []
        this.total_1 = this.Unpublished2.length
        this.Unpublished2.forEach(v => {
          if (v.planAnalysisState === 1) {
            this.$set(v, 'planAnalysisState', true)
          } else {
            this.$set(v, 'planAnalysisState', false)
          }
        })
      }
      if (status2) {
        this.Published2 = list.filter(v => v.scheduledStatus == 3)
        if (!status1) this.Unpublished2 = []
        this.total_2 = this.Published2.length
        this.Published2.forEach(v => {
          if (v.planAnalysisState === 1) {
            this.$set(v, 'planAnalysisState', true)
          } else {
            this.$set(v, 'planAnalysisState', false)
          }
        })
      }
    },
    async checked(val) {
      let params = {
        id: val.id,
        planAnalysisState: val.planAnalysisState ? 1 : 0
      }
      const res = await updateSelected(params)
      if (res.code != 0) {
        this.$message.error({
          showClose: true,
          message: '操作失败，请稍后重试！',
          duration: 0
        })
      } else {
        this.status === 1 ? this.getData(this.currentPage1_1, this.pageChange_1, 1) :
          this.getData(this.currentPage1_2, this.pageChange_2, 3)
      }
      if (this.status === 1) {
        if (val.planAnalysisState) {
          this.temp1.push({
            id: val.id,
          })
        } else {
          // 删除选中
          this.temp1.splice(this.temp1.findIndex(v => v.id === val.id), 1)
        }
        if (this.temp1.length === this.allData1.length) {
          // 说明全选了
          const res = await allSelected(1, 1)
          if (res.code != 0) {
            this.$message.error({
              showClose: true,
              message: '操作失败，请稍后重试！',
              duration: 0
            })
          } else {
            this.$set(this, 'checkAll1', true)
          }
        } else if (this.temp1.length === 0 && this.Unpublished2.length === this.allData1.length) {
          const res = await allSelected(1, 0)
          if (res.code != 0) {
            this.$message.error({
              showClose: true,
              message: '操作失败，请稍后重试！',
              duration: 0
            })
          } else {
            this.$set(this, 'checkAll1', false)
          }
        } else if (this.temp1.length < this.total_1) {
          this.$set(this, 'checkAll1', false)
        }
      } else if (this.status === 3) {
        if (val.planAnalysisState) {
          this.temp2.push({
            id: val.id
          })
        } else {
          // 删除选中
          this.temp2.splice(this.temp2.findIndex(v => v.id === val.id), 1)
        }
        if (this.temp2.length === this.allData2.length) {
          // 说明全选了
          const res = await allSelected(3, 1)
          if (res.code != 0) {
            this.$message.error({
              showClose: true,
              message: '操作失败，请稍后重试！',
              duration: 0
            })
          } else {
            this.$set(this, 'checkAll2', true)
          }
        } else if (this.temp2.length === 0 && this.Published2.length === this.allData2.length) {
          const res = await allSelected(3, 0)
          if (res.code != 0) {
            this.$message.error({
              showClose: true,
              message: '操作失败，请稍后重试！',
              duration: 0
            })
          } else {
            this.$set(this, 'checkAll2', false)
          }
        } else if (this.temp2.length < this.total_2) {
          this.$set(this, 'checkAll2', false)
        }
      }
    },
    async getAllData() {
      await this.getData(1, 10, 1)  // 获取未发布数据
      await this.getData(1, 10, 2)  // 获取使用中数据
      await this.getData(1, 10, 3)  // 获取已发布数据
    }
  },
  mounted() {
    this.$nextTick(() => {
      let total_1 = sessionStorage.getItem('total_1')
      let total_2 = sessionStorage.getItem('total_2')
      if (Number(total_1)) this.getData(1, total_1, 1, 1)
      if (Number(total_2)) this.getData(1, total_2, 3, 3)
    })
  },
  async created() {
    if (!sessionStorage.getItem('mark')) {
      await allSelected(1, 0)
      await allSelected(3, 0)
    }
    sessionStorage.setItem('mark', true)
    await this.getAllData()
    this.input2 = ''

  }
}
</script>

<style lang="sass" scoped>
@import './style/style.scss'
</style>
